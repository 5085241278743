<template>
    <div ref="load" class="zt-page-content" style="overflow:unset;">
        <div class="fj-block">
            <div v-if="item && item.id" class="fj-top">
                <div v-if="item.name">{{'单品'}}名称：{{item.name}}</div>
                <div v-if="item.cat && item.cat.name">类目：{{item.cat.name}}</div>
            </div>
            <div class="fj-main">
                <!-- 编辑sku -->
                <EditSku v-if="catId" :catId="catId" :module_no="item.module_no" :skuType="skuType" v-model="skus"  ref="editskus"></EditSku>
            </div>
        </div>
        <div v-if="item" class="zt-block btn-block">
            <el-button @click="back()" type="primary">取消</el-button>
            <el-button @click="skuSubmit(0)" type="primary">保存</el-button>
            <!-- <el-button @click="submitShow()" type="primary">发布</el-button> -->
        </div>

        <!-- 发布确认弹框 -->
        <el-dialog width="540px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <div v-if="popTitle=='温馨提示'">
                <div style="width:80%;margin:0 auto;text-align:center;padding-bottom:20px;">
                    <p style="font-size:16px;color:#292929;">商品发布确认</p>
                    <p style="font-size:14px;color:#808080;">商品发布后，会在集市和销售手册中展示，请仔细核对商品详情</p>
                </div>
                <div style="text-align:center;border-top:1px solid #ccc;padding-top:25px;">
                    <el-button style="margin-right:20px;" @click="submitHide()" size="small" type="primary" plain>再修改一下</el-button>
                    <el-button size="small" type="primary" @click="skuSubmit(1)">确认并发布</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Loading } from 'element-ui';
import _ from 'lodash';
import { updateFxSelfProductSku,fxDetailProduct } from '@/service/sharer';
import EditSku from '@/components/sku/Edit.vue';
export default {
    components: { EditSku },
    data() {
        return {
            popTitle:"",
            popVisible:false,
            item:null,
            itemId:null,// 附件或者商品的ID
            loading:null,
            catId:null,
            skus:[],
            skuType:1,// 1：集市自有商品的Sku
        }
    },
    created() {
        this.detail();
    },
    mounted() {
        this.loading = Loading.service({
            fullscreen: true, text: '拼命加载中....',
            target:this.$refs.load
        });
    },
    methods:{
        back() {
            this.$router.go(-1);
        },
        goto(path) {
            this.$router.push(path);
        },
        detail() {
            fxDetailProduct(this.$route.params.id).then(rst=>{
                if (rst) {
                    this.item=rst;
                    this.itemId = rst.id;
                    if (this.item && this.item.cat && this.item.cat.id) {
                        this.catId = this.item.cat.id;
                    }
                    console.log(this.catId);
                    this.skus=this.item.sku_list;
                }
                console.log(this.item);
            });
        },
        async valid(){
            console.log("111skus",this.skus);
            if (this.skus && this.skus.length>0) {
                await this.$refs.editskus.valid();
                return this.skus;
            } 
            else {
                this.$message.error("请完善sku信息");
                return "";
            }

        },
        submit(obj,type) {
            const _loading = this.$loading({
                lock: true,
                text: '拼命加载中......',
            });
            updateFxSelfProductSku(obj).then(rst => {
                _loading.close();
                // this.$message.success(type==1 ? '发布成功' : '保存成功');
                this.$message.success('保存成功');
                this.back();
            }).catch(err => {
                _loading.close();
                this.$message.error(err.message);
            });
        },
        async skuSubmit(type){
            this.valid().then(rst => {
                if (rst && rst.length>0) {
                    let _item = _.clone(rst);
                    // console.log(_item);
                    let obj = {
                        fx_product_id:this.itemId,
                        sku_list:_item,
                    }
                    this.submit(obj);
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
        },
        async submitShow() {
            this.valid().then(rst => {
                console.log("rst",rst);
                if (rst && rst.length>0) {
                    this.popTitle = "温馨提示";
                    this.popVisible = true;
                }
            }).catch(err => {
                this.$message.error(err.message);
            });
            // if (this.$store.state.loginUser.comp.cert_stat!=2) {
            //     this.$message.error("企业未认证");
            //     return;
            // } else {
            //     this.$refs.getSku.valid().then(rst => {
            //         // console.log(rst);
            //         if (rst && rst.length>0) {
            //             this.popTitle = "温馨提示";
            //             this.popVisible = true;
            //         }
            //     }).catch(err => {
            //         this.$message.error(err.message);
            //     });
            // }
        },
        submitHide() {
            this.popVisible = false;
        },
    },
    updated() {
        this.loading.close();
    },
}
</script>
<style src="../style.css"></style>
<style scoped lang="less">
.fj-block {
    margin: 0 20px;
    .fj-top {
        padding: 20px;
        position: relative;
        >div {
            color: #4D4D4D;
            font-size: 16px;
            font-weight:600;
            line-height: 22px;
            margin-bottom: 8px;
            &:nth-child(2) {
                margin-bottom: 0;
            }
        }
    }
}
.zt-page-content {
    min-height: 800px;
    background: #F5F5F5;
    .add-btn {
        height: 80px;
        color: #016FFF;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 46px;
        cursor: pointer;
        i {
            font-size: 18px;
            margin-right: 16px;
        }
    }
    .block-btm {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .select-container {
            position: absolute;
            left: 50px;
            display: flex;
            align-items: center;
            span {
                color: #D70000;
                font-size: 22px;
                position: relative;
                top: 4px;
            }
            b {
                margin: 0 23px 0 5px;
            }
        }
    }
    .btn-block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
        .el-button {
            margin-left: 48px;
        }
        a {
            margin-left: 48px;
            margin-right: 10px;
        }
    }
}
</style>